.homefourthpart{
    width: 100%;
    height: 600px;
    background-image: url('../imgs/homepartfourbg.png');
    position: relative;
}

.homefourthpart .homefourthpart-text{
    position: absolute;
    right: 10%;
    top: 30%;
    width: 50%;
    text-shadow: 0 4px 4px rgb(255, 255, 255);
}