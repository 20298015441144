.navigation-style {
    font-size: 14px;
    font-weight: bold;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    font-family:"Nunito", sans-serif;
    --bs-navbar-color: rgb(58, 58, 58) !important;
    --bs-navbar-hover-color: rgb(66, 66, 168) !important;
    --bs-navbar-active-color: rgb(66, 66, 168) !important;
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    top: 0 !important;
    z-index: 999;
}

.navigation-style li{
    margin-left: 5px;
    margin-right: 5px;
}

.navigation-logo{
    padding-left: 10%;
    padding-right: 5%;
}