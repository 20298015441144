.carselcaption-style{
    position: absolute;
    left: 15%;
    top: 35%;
    right:35%;
    text-align: left;
    font-family:"Nunito", sans-serif;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /*pic size 1800*600*/
}

.home-first-part-items {
    position: relative;
}

.home-first-part-items-text {
    position: absolute;
    left: 20%;
    top: 35%;
    font-family:"Nunito", sans-serif;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    width: 40%;
}

.home-first-part-items h3{
    font-size: 350%;
}

.home-first-part-items p{
    font-size: 150%;
}

@media screen and (max-width: 450px){
    .carselcaption-style{
        position: absolute;
        left:0;
        top:0;
        right: auto;
    }
    .carselcaption-style h3{
        font-size: 100%;
    }
    
    .carselcaption-style p{
        font-size: 100%;
    }

    .d-none{
        display: block !important;
    }
}