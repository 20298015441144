.footer-container {
  background-image: url('../imgs/homepic4.png');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container li{
  list-style-type: none;
}

.footer-link-container {
  display: flex;
  flex-direction: row;
  margin: 10px auto 40px;
}

.footer-link-items {
  width: 250px;
}

.footer-link-items .footer-link-title h3 {
  color: #f1dbc4;
  margin: 15px;
  margin-left: 0;
}

.textwidget {
  color: white;
}

.footer-links {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 16px;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
}

.footer-links:hover {
  color: rgb(30, 29, 109);
  font-weight: bold;
}

.middle-line {
  display: flex;
  align-items: center;
  margin: 16px 0;
}
/* Social Icons */
.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #000;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.footer-menu {
  padding-left: 0 !important;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}
