.contact-detail-address {
    width: 100%;
    display: flex;
    height: 600px;
    background-color: black;
  }

  .contact-detail-map {
    width: 100%;
    opacity: 0.9;
    position: relative;
  }

  .contact-detail-div {
    position: absolute;
    left: 10%;
    top: 60%;
    background-color: white;
    padding: 20px;
    border-radius: 5%;
    width: 250px;
    opacity: 0.9;
  }

  .contact-detail-div:hover {
    opacity: 1;
  }

  .contact-detail-div > div {
    margin:10px auto;
  }
  
  .contact-detail-div-time{
    font-size: 13px;
    color: grey;
  }

  .contact-detail-div-company {
    font-weight: bold;
    font-size: 20px;
    color: rgb(66, 66, 168);
  }