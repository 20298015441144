.homethirdpart-style{
    background-color: rgb(241, 242, 250);
    width: 100%;
    position: relative;
}

.homethirdpart-style .homethirdpart-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30%;
    opacity: 8%;
    overflow: hidden;
}

.homethirdpart-style h1{
    color: rgb(6, 14, 105);
}

.wwd-third-container{
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.wwd-third-div {
    height: 450px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.wwd-third-left-div{
    width: 50%;
    height: 70%;
    float: left;
}

.wwd-third-left-div img{
    width: 100%;
    height: 100%;
}


.wwd-third-right-div{
    width: 50%;
    height: 70%;
    line-height: 100%;
    background-color: white;
    float: left;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 30px;
}

.wwd-third-right-div h3{
    padding: 5px auto;
}

.wwd-third-right-div ul{
    padding-top: 15px;
}

.homethirdpart-style .carousel-control-prev {
    left: -200px !important;
}

.homethirdpart-style .carousel-control-next {
    right: -200px !important;
}

@media screen and (max-width: 450px){
    .wwd-third-container{
        text-align: center;
        margin-left: 5%;
        margin-right: 5%;
    }
    .wwd-third-div {
        height: 150px;
    }
    .about-text h2{
        font-size: 15px;
    }
    .homethirdpart-style .carousel-control-prev {
        left: 0 !important;
    }
    
    .homethirdpart-style .carousel-control-next {
        right: 0 !important;
    }
    .wwd-third-left-div{
        width: 100%;
        height: 70%;
        float: none;
    }
    .wwd-third-right-div{
        width: 100%;
        height: 200%;
        float: none;
        display: block;
    }
}