.what-first-part-div{
    width: 75%;
    margin: 80px auto;
}

.what-first-part-heading {
    width: 50%;
    font-size: 45px;
}

.what-first-part-content {
    width: 70%;
}

.what-first-part-icon-group {
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    background-color: rgba(240, 240, 240, 0.778);
    padding: 50px;
}

.what-first-part-icon-group div{
    width: 290px;
    height: 290px;
    position: relative;
    background-color: rgb(66, 66, 168);
}

.what-first-part-icon-group>div img{
    width: 100%;
    height: 100%;
}

.what-first-part-icon-group>div img:hover{
    opacity: 0.5;
}

.what-first-part-icon-group>div p{
    position: absolute;
    bottom: 20%;
    left: 0;
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    color: white;
}

.what-first-part-icon-group>div p[id='1']{
    left: 10%;
}
.what-first-part-icon-group>div p[id='2']{
    left: 5%;
}
.what-first-part-icon-group>div p[id='3']{
    left: 30%;
}