.banner-div {
    padding: 2.7% 20px;
    background: #a10b0c !important;
    background: -moz-linear-gradient(left, #a10b0c 0%, #a10b0c 100%) !important;
    background: -webkit-linear-gradient(left, #a10b0c 0%,#a10b0c 100%) !important;
    background: linear-gradient(to right, #a10b0c 0%,#a10b0c 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a10b0c', endColorstr='#a10b0c',GradientType=1 ) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: url(../imgs/homepic4.png) !important;
    height: 50%;
}

.banner-div-center{
    position: relative;
    margin: 0 auto;
    width: 1170px;
    color: white;
}

.banner-breadcrumb a{
    display: inline-block;
    padding-right: 10px;
}