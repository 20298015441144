.about-part-div {
    position: relative;
    margin: 0 auto;
    width: 1170px;
    padding-top: 5%;
    padding-bottom: 5%;
}

.about-part-div h3{
    position: relative;
    width: 100%;
    display: block;
    color: #2c555f;
    font-family: 'Roboto Slab';
    font-size: 20px;
}

.about-part-div h2 {
    position: relative;
    width: 100%;
    display: block;
    color: #120b61;
    font-size: 32px;
    font-weight: 900;
    font-family: 'Roboto Slab';
    margin-bottom: 20px;
}

.about-part-div p {
    position: relative;
    width: 100%;
    color: #4b4e50;
    font-size: 18px;
}


.about-first-part-left, .about-second-part-right{
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 42%;
    padding-top: 80px;
}

.about-first-part-left img {
    width: 400px;
}

.about-second-part-right img {
    width: 400px;
    margin-left: 50px;
}

.about-first-part-right, .about-second-part-left{
    position: relative;
    width: 56%;
    display: inline-block;
    vertical-align: middle;
    padding-top: 80px;
}

@media screen and (max-width: 1280px){
    .about-part-div {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .about-first-part-left{
        width: 100%;
    }

    .about-first-part-right{
        width: 100%;
    }
}