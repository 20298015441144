.approach-container{
    height: 700px;
    width: 100%;
}

.approach-container-1{
    background: url('../imgs/safety_bg.png') center center/cover no-repeat;
}

.approach-container-2{
    background: url('../imgs/community_bg.png') center center/cover no-repeat;
}

.approach-container-3{
    background: url('../imgs/diversity_bg.png') center center/cover no-repeat;
}

.approach-container-4{
    background: url('../imgs/sustain_bg.png') center center/cover no-repeat;
}

.approach-heading{
    margin-left: 30%;
    margin-top: auto;
    margin-bottom: auto;
    padding-top:5%;
    display: block;
    /*overflow: auto;*/
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 9vw;
    color: white;
}

.approach-content{
    margin-left: 30%;
    color: white;
    font-size: 1.5vw;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 50%;
}

@media screen and (max-width: 410px){
    .approach-content{
        font-size: 4vw;
    }
}