.career-first-part-div{
    width: 75%;
    margin: 70px auto;
}

.career-first-part-heading {
    width: 50%;
    font-size: 45px;
    margin-top: 60px;
}

.career-first-part-content {
    width: 70%;
}

.career-first-part-icon-group {
    margin: 50px auto;
    /*display: flex;
    justify-content: space-between;*/
    background-color: rgba(240, 240, 240, 0.778);
    padding: 50px;
}

.career-first-part-icon-group div{
    width: 190px;
    height: 190px;
    position: relative;
    background-color: rgb(66, 66, 168);
    display: inline-block;
    margin: 5px;
}

.career-first-part-icon-group>div img{
    width: 100%;
    height: 100%;
}

.career-first-part-icon-group>div img:hover{
    opacity: 0.5;
}

.career-first-part-icon-group>div p{
    position: absolute;
    bottom: 20%;
    left: 0;
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    color: white;
}

.career-first-part-icon-group>div p[id='1']{
    left: 10%;
}
.career-first-part-icon-group>div p[id='2']{
    left: 5%;
}
.career-first-part-icon-group>div p[id='3']{
    left: 20%;
}

.career-first-part-icon-group>div p[id='4']{
    left: 2%;
    font-size: 20px;
}