/*.vertical-timeline-div{
    background-color: rgb(231, 230, 230);
    width: 100% !important;
}*/

/*.vertical-timeline {
    max-width: 3000px;
    padding: 150px 300px;
}

.history-timeline-first-part-div {
    position: relative;
    margin: 0 auto;
    width: 1170px;
    padding-top: 5%;
    padding-bottom: 5%;
}

.history-timeline-first-part-div p {
    position: relative;
    width: 100%;
    color: #4b4e50;
    font-size: 15px;
}*/

.vertical-timeline-div {
    margin-top: 150px;
    margin-bottom: 40px;
}

.vertical-timeline-element-content-arrow {
    border-right: 7px solid rgb(243, 242, 241);
}

.vertical-timeline-element-content {
    background: rgb(243, 242, 241);
}

.vertical-timeline-element-image {
    padding-top: 20px;
}

.vertical-timeline-element-image img{
    width: 95px;
    height: 45px;
    margin-right: 20px;
}