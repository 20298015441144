.contactus-div {
    width: 100%;
    height: 250px;
    background-color: white;
    /*background-image: url('../imgs/contactus.png');*/
}

.contactus-subdiv{
    margin: 0 auto;
    width: 15%;
    height: 250px;
    line-height: 250px;
}

.contactus-button {
    --bs-btn-bg: #1953ab !important;
    --bs-btn-color:white !important;
    --bs-btn-hover-color: #0b5ed7 !important;
    --bs-btn-hover-bg: #fff !important;
    --bs-btn-hover-border-color: #fff !important;
    width: 100%;
    height: 20%;
    vertical-align: middle;
    
}

.contactus-button a{
    color:white !important;
    text-decoration: none !important;
}

.contactus-button a:hover{
    color:#1953ab !important;
}

@media screen and (max-width: 950px){
    .contactus-subdiv{
        width: 50%;
    }
}