.homesecondpart-style{
    width: 100%;
    height: 300px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.homesecondpart-content{
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
}
.homesecondpart-box{
    position: relative;
    float: left;
    width: 24.8%;
    height: 288px;
    background-color: rgb(66, 66, 168);
    padding: 2%;
    padding-bottom: 0;
    color: white;
    overflow: auto;
    margin: 0.1%;
}
.homesecondpart-box img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60%;
    opacity: 8%;
}
.homesecondpart-box h3{
    text-align: center;
    font-family: 'Heebo', sans-serif;
}
.homesecondpart-box p{
    font-size: 15px;
}

.homesecondpart-box:hover{
    background-color: rgb(85, 85, 182);
}